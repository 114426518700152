import React from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Faq2 = () => {
  return (
    <>
      <Layout>
        <Container>
          <Row>
            <div className="back-button mb-4 mt-3">
              <Link to="/faq/">&larr; Back</Link>
            </div>
            <Col sm="12">
              <div className="mb-5">
                <StaticImage
                  src="../../images/man-on-ylw-background.jpg"
                  alt="Man thinking"
                  placeholder="none"
                  layout="fullWidth"
                />
                <h2 className="mt-3">
                  What's the difference between Lumineers<sup>&reg;</sup> and
                  veneers?
                </h2>
                <p>
                  Lumineers are designed to deliver a beautiful smile
                  transformation with a simple, painless procedure. Lumineers
                  are custom made exclusively by the DenMat Lab in Lompoc,
                  California, USA, by technicians that specialize in crafting
                  esthetic dental veneers. Lumineers are digitally designed to
                  be ultra-thin (about as thin as a contact lens), so they can
                  be applied seamlessly over your teeth to deliver a
                  natural-looking smile. Traditional porcelain veneers are
                  generally thicker, and usually require more aggressive and
                  irreversible removal of healthy tooth structure. The extensive
                  preparation required for traditional porcelain veneers
                  frequently requires anesthesia shots to minimize sensitivity
                  and pain, and typically involves placement of acrylic
                  temporaries in between appointments. The minimally invasive
                  Lumineers technique typically eliminates the need for shots or
                  temporaries, so you will still look and feel great while your
                  Lumineers are crafted and delivered by the lab.
                </p>
              </div>
              <div className="mb-5 text-center">
                <Link to="../../procedure/" className="intro-button mb-5">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Faq2
